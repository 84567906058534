import comingSoon from "../Data/comingsoon.png";
import '../pages/Projects.css';


export default function Projects () {
    return (
        <div>
            <img className="coming-soon-img" src={comingSoon} alt="coming very soon to a website near you."/>
        </div>
    )
};